exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aerial-aerial-yaml-id-tsx": () => import("./../../../src/pages/aerial/{aerialYaml.id}.tsx" /* webpackChunkName: "component---src-pages-aerial-aerial-yaml-id-tsx" */),
  "component---src-pages-aerial-index-tsx": () => import("./../../../src/pages/aerial/index.tsx" /* webpackChunkName: "component---src-pages-aerial-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-project-index-tsx": () => import("./../../../src/pages/project/index.tsx" /* webpackChunkName: "component---src-pages-project-index-tsx" */),
  "component---src-pages-project-water-crisis-index-tsx": () => import("./../../../src/pages/project/water-crisis/index.tsx" /* webpackChunkName: "component---src-pages-project-water-crisis-index-tsx" */),
  "component---src-pages-project-water-crisis-water-yaml-id-tsx": () => import("./../../../src/pages/project/water-crisis/{waterYaml.id}.tsx" /* webpackChunkName: "component---src-pages-project-water-crisis-water-yaml-id-tsx" */),
  "component---src-pages-wedding-index-tsx": () => import("./../../../src/pages/wedding/index.tsx" /* webpackChunkName: "component---src-pages-wedding-index-tsx" */),
  "component---src-pages-wedding-wedding-yaml-id-tsx": () => import("./../../../src/pages/wedding/{weddingYaml.id}.tsx" /* webpackChunkName: "component---src-pages-wedding-wedding-yaml-id-tsx" */)
}

